import {
  useFUserControllerCount,
  useFUserControllerCreate,
  useFUserControllerDeleteById,
  useFUserControllerFind,
  useFUserControllerUpdateById,
} from 'main/apis/drc/endpoints/fuser-controller/fuser-controller';
import {
  UserPartialExcludingIdUsername,
  UserExcludingId,
  UserExcludingPassword,
  UserFilter1,
} from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import EditPermissionAction from 'main/components/molecules/permission-action/EditPermissionAction';
import { Select2Box } from 'main/components/molecules/selectbox';
import DeleteModal from 'main/components/organisms/modal/delete-modal';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import useDepartmentStoreOptions from 'main/hooks/useDepartmentStoreOptions';
import { usePermissionAction } from 'main/hooks/usePermissionAction';
import useTitleStoreOptions from 'main/hooks/useTitleStoreOptions';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import tableConfig from './table-config';
import UserModal from './users-management-add-edit';

const UsersManagementList: React.FC = () => {
  const permissionAction = usePermissionAction();
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserExcludingPassword | undefined>(undefined);
  const [usernameTextValue, setUsernameTextValue] = useState<string>('');
  const [usernameForFilter, setUsernameForFilter] = useState<string | undefined>(undefined);
  const [titleIdSelected, setTitleIdSelected] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);

  const { departmentStoreOptions: departmentOptions, isSuccess: departmentDataSuccess } =
    useDepartmentStoreOptions(true);
  const { titleStoreOptions: titleOptions, isSuccess: titleDataSuccess } = useTitleStoreOptions(true);

  const searchFilter: UserFilter1 = useMemo(
    () => ({
      fields: {
        id: true,
        username: true,
        departmentId: true,
        titleId: true,
      },
      where: {
        ...(titleIdSelected && titleIdSelected !== '-1' ? { titleId: titleIdSelected } : {}),
        ...(usernameForFilter?.trim().length ? { username: { like: `%${usernameForFilter}%` } } : {}),
      },
      limit: sizePerPage,
      skip: (page - 1) * sizePerPage,
    }),
    [page, sizePerPage, titleIdSelected, usernameForFilter],
  );

  const {
    data: userData,
    refetch: refetchUser,
    isLoading,
  } = useFUserControllerFind(
    { filter: searchFilter },
    {
      query: {
        enabled: departmentDataSuccess && titleDataSuccess,
        staleTime: 0,
      },
    },
  );
  const { data: countItems, refetch: refetchCount } = useFUserControllerCount({
    where: searchFilter.where,
  });
  const { mutateAsync: deleteUserAsync } = useFUserControllerDeleteById();
  const { mutateAsync: updateUserAsync, isPending: isUpdatePending } = useFUserControllerUpdateById();
  const { mutateAsync: createUserAsync, isPending: isAddPending } = useFUserControllerCreate();

  const toggleDeleteModal = useCallback(() => setOpenDeleteUserModal(!openDeleteUserModal), [openDeleteUserModal]);

  const showDeleteUserModal = useCallback(
    (user: UserExcludingPassword) => {
      setSelectedUser(user);
      toggleDeleteModal();
    },
    [toggleDeleteModal],
  );

  const showEditUserModal = useCallback((user: UserExcludingPassword) => {
    setSelectedUser(user);
    setOpenUserModal(true);
  }, []);

  const showAddUserModal = useCallback(() => {
    setSelectedUser(undefined);
    setOpenUserModal(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (selectedUser?.id) {
      deleteUserAsync({ id: selectedUser.id })
        .then(() => {
          setPage(1);
          refetchUser();
          refetchCount();
          toggleDeleteModal();
          toast.success(
            <div>
              <b>Thành công</b> <br /> Xóa thành công Người dùng: {selectedUser.username}
            </div>,
          );
        })
        .catch((error) => {
          toast.error(
            <div>
              <b>Thất bại</b> <br /> Có lỗi xảy ra khi xóa Người dùng. <br />
              {error?.response?.data?.error?.message || error.message}
            </div>,
          );
        });
    }
  }, [deleteUserAsync, refetchCount, refetchUser, selectedUser?.id, selectedUser?.username, toggleDeleteModal]);

  const handleUpdateUser = useCallback(
    (id: number, updatedUser: UserPartialExcludingIdUsername) => {
      if (id === null || id === undefined || id !== selectedUser?.id) {
        return toast.error('<b>Thất bại</b> <br> Dữ liệu cập nhật Người dùng không hợp lệ');
      }

      updateUserAsync({ id, data: updatedUser })
        .then(() => {
          refetchUser();
          toast.success(
            <div>
              <b>Thành công</b> <br /> Đã cập nhật thông tin Người dùng: {selectedUser.username}
            </div>,
          );
          if (updatedUser.password) {
            toast.success('Mật khẩu đã được tạo mới thành công.');
          }
          setOpenUserModal(false);
        })
        .catch(() => {
          toast.error(
            <div>
              <b>Thất bại </b> <br /> Đã có lỗi xảy ra, cập nhật thông tin Người dùng thất bại.
            </div>,
          );
        });
    },
    [refetchUser, selectedUser, updateUserAsync],
  );

  const handleAddNewUser = useCallback(
    (newUser: UserExcludingId) => {
      createUserAsync({ data: newUser })
        .then(() => {
          toast.success(
            <div>
              <b>Thành công</b> <br />
              Tạo mới Người dùng thành công.
            </div>,
          );
          refetchUser();
          refetchCount();
          setOpenUserModal(false);
        })
        .catch((error) => {
          toast.error(
            <div>
              <b>Thất bại</b> <br />
              {error?.response?.data?.error?.message || error.message}
            </div>,
          );
        });
    },
    [createUserAsync, refetchCount, refetchUser, setOpenUserModal],
  );

  const handleUsernameFilter = useCallback(() => {
    setUsernameForFilter(usernameTextValue);
    setPage(1);
  }, [usernameTextValue]);

  const handleResetSearch = useCallback(() => {
    setUsernameTextValue('');
    setUsernameForFilter(undefined);
    setTitleIdSelected('-1');
    setPage(1);
  }, []);

  const handleTableChange = useCallback((type: TableChangeType, newState: TableChangeState<UserExcludingPassword>) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
  }, []);

  return (
    <>
      <BasicTemplate
        pageCategory={Page.USER_MANAGEMENT.CATEGORY}
        pageTitle={Page.USER_MANAGEMENT.TITLE}
        pageName={Page.USER_MANAGEMENT.NAME}>
        <Container className="mt--6" fluid>
          <Row key="users">
            <div className="col">
              <Card>
                <CardHeader>
                  <h3>QUẢN LÝ NGƯỜI DÙNG</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="itemSelect">Lọc theo Chức danh</Label>
                        <Select2Box
                          className="form-control"
                          options={{ placeholder: 'Lọc theo Chức danh' }}
                          value={titleIdSelected}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => setTitleIdSelected(e.target.value)}
                          data={titleOptions}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="searchName">Username</Label>
                        <Input
                          type="text"
                          id="searchName"
                          value={usernameTextValue}
                          onChange={(e) => setUsernameTextValue(e.target.value)}
                          placeholder="Tìm theo username"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col md={5} className="d-flex justify-content-end">
                      <Button color="secondary" onClick={handleResetSearch}>
                        Xóa bộ lọc
                      </Button>
                      <Button color="primary" onClick={handleUsernameFilter}>
                        Tìm
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="justify-content-end mb-5">
                    <Col md={2} className="d-flex justify-content-end">
                      <EditPermissionAction>
                        <Button color="primary" onClick={showAddUserModal}>
                          Thêm mới
                        </Button>
                      </EditPermissionAction>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <ToolkitProvider
                          keyField="id"
                          data={userData ?? []}
                          columns={tableConfig.columns(
                            departmentOptions,
                            titleOptions,
                            showEditUserModal,
                            showDeleteUserModal,
                            permissionAction,
                          )}
                          search>
                          {(props: any) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={tableConfig.pagination(page, sizePerPage, countItems?.count ?? 0)}
                                remote
                                onTableChange={handleTableChange}
                                bordered={false}
                                noDataIndication={() => (
                                  <div className="text-center">
                                    {isLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                                  </div>
                                )}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Card>
                    </Col>
                  </Row>
                  {selectedUser && (
                    <DeleteModal
                      messageItem={`Người dùng: ${selectedUser.username}`}
                      modal={openDeleteUserModal}
                      toggle={toggleDeleteModal}
                      handleDelete={handleDelete}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </BasicTemplate>
      <UserModal
        isOpen={openUserModal}
        toggle={() => setOpenUserModal(!openUserModal)}
        handleAdd={handleAddNewUser}
        handleEdit={handleUpdateUser}
        departmentOptions={departmentOptions.map((option) =>
          option.id === '-1' ? { ...option, text: 'Không lựa chọn' } : option,
        )}
        titleOptions={titleOptions.map((option) =>
          option.id === '-1' ? { ...option, text: 'Không lựa chọn' } : option,
        )}
        selectedUser={selectedUser}
        isAddPending={isAddPending}
        isUpdatePending={isUpdatePending}
      />
    </>
  );
};

export default UsersManagementList;
