/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DonHangChartTongDonDatHang,
  DonHangDashboardModel,
  LoopbackCount,
  NewQldhTransDonHang,
  QldhTransDonHang,
  QldhTransDonHangControllerCountParams,
  QldhTransDonHangControllerFindByIdParams,
  QldhTransDonHangControllerFindParams,
  QldhTransDonHangControllerSearchCanhBao200Item,
  QldhTransDonHangControllerSearchItems200,
  QldhTransDonHangControllerSearchItemsParams,
  QldhTransDonHangControllerSearchThongKe200Item,
  QldhTransDonHangControllerUpdateTrangThaiDonHangBody,
  QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations,
  QldhTransDonHangWithRelations
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const qldhTransDonHangControllerCount = (
    params?: QldhTransDonHangControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/qldh-trans-don-hangs/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransDonHangControllerCountQueryKey = (params?: QldhTransDonHangControllerCountParams,) => {
    return [`/qldh-trans-don-hangs/count`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransDonHangControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>, TError = ErrorType<unknown>>(params?: QldhTransDonHangControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>> = ({ signal }) => qldhTransDonHangControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>>
export type QldhTransDonHangControllerCountQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerCount = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>, TError = ErrorType<unknown>>(
 params?: QldhTransDonHangControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerGetChartTongDonDatHangTheoKho = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DonHangChartTongDonDatHang[]>(
      {url: `/qldh-trans-don-hangs/dashboard/tong-don-dat-hang-theo-kho`, method: 'GET', signal
    },
      );
    }
  

export const getQldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryKey = () => {
    return [`/qldh-trans-don-hangs/dashboard/tong-don-dat-hang-theo-kho`] as const;
    }

    
export const getQldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>> = ({ signal }) => qldhTransDonHangControllerGetChartTongDonDatHangTheoKho(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>>
export type QldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerGetChartTongDonDatHangTheoKho = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerGetChartTongDonDatHangTheoKho>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerGetChartTongDonDatHangTheoKhoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerCountDonHangDashboard = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DonHangDashboardModel>(
      {url: `/qldh-trans-don-hangs/dashboard`, method: 'GET', signal
    },
      );
    }
  

export const getQldhTransDonHangControllerCountDonHangDashboardQueryKey = () => {
    return [`/qldh-trans-don-hangs/dashboard`] as const;
    }

    
export const getQldhTransDonHangControllerCountDonHangDashboardQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerCountDonHangDashboardQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>> = ({ signal }) => qldhTransDonHangControllerCountDonHangDashboard(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerCountDonHangDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>>
export type QldhTransDonHangControllerCountDonHangDashboardQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerCountDonHangDashboard = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCountDonHangDashboard>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerCountDonHangDashboardQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerUpdateBaseById = (
    id: number,
    qldhTransDonHang: QldhTransDonHang,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-don-hangs/exclude-relations/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: qldhTransDonHang
    },
      );
    }
  


export const getQldhTransDonHangControllerUpdateBaseByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>, TError,{id: number;data: QldhTransDonHang}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>, TError,{id: number;data: QldhTransDonHang}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>, {id: number;data: QldhTransDonHang}> = (props) => {
          const {id,data} = props ?? {};

          return  qldhTransDonHangControllerUpdateBaseById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerUpdateBaseByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>>
    export type QldhTransDonHangControllerUpdateBaseByIdMutationBody = QldhTransDonHang
    export type QldhTransDonHangControllerUpdateBaseByIdMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerUpdateBaseById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>, TError,{id: number;data: QldhTransDonHang}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateBaseById>>,
        TError,
        {id: number;data: QldhTransDonHang},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerUpdateBaseByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerUpdateStatusById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-don-hangs/hoan-thanh/${id}`, method: 'PATCH'
    },
      );
    }
  


export const getQldhTransDonHangControllerUpdateStatusByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  qldhTransDonHangControllerUpdateStatusById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerUpdateStatusByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>>
    
    export type QldhTransDonHangControllerUpdateStatusByIdMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerUpdateStatusById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateStatusById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerUpdateStatusByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerImport = (
    qldhTransDonHangWithRelations: QldhTransDonHangWithRelations[],
 ) => {
      
      
      return drcInstance<QldhTransDonHangWithRelations[]>(
      {url: `/qldh-trans-don-hangs/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: qldhTransDonHangWithRelations
    },
      );
    }
  


export const getQldhTransDonHangControllerImportMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>, TError,{data: QldhTransDonHangWithRelations[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>, TError,{data: QldhTransDonHangWithRelations[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>, {data: QldhTransDonHangWithRelations[]}> = (props) => {
          const {data} = props ?? {};

          return  qldhTransDonHangControllerImport(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerImportMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>>
    export type QldhTransDonHangControllerImportMutationBody = QldhTransDonHangWithRelations[]
    export type QldhTransDonHangControllerImportMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerImport = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>, TError,{data: QldhTransDonHangWithRelations[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerImport>>,
        TError,
        {data: QldhTransDonHangWithRelations[]},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerSearchCanhBao = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransDonHangControllerSearchCanhBao200Item[]>(
      {url: `/qldh-trans-don-hangs/search-canh-bao`, method: 'GET', signal
    },
      );
    }
  

export const getQldhTransDonHangControllerSearchCanhBaoQueryKey = () => {
    return [`/qldh-trans-don-hangs/search-canh-bao`] as const;
    }

    
export const getQldhTransDonHangControllerSearchCanhBaoQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerSearchCanhBaoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>> = ({ signal }) => qldhTransDonHangControllerSearchCanhBao(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerSearchCanhBaoQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>>
export type QldhTransDonHangControllerSearchCanhBaoQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerSearchCanhBao = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchCanhBao>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerSearchCanhBaoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerSearchItems = (
    params?: QldhTransDonHangControllerSearchItemsParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransDonHangControllerSearchItems200>(
      {url: `/qldh-trans-don-hangs/search-items`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransDonHangControllerSearchItemsQueryKey = (params?: QldhTransDonHangControllerSearchItemsParams,) => {
    return [`/qldh-trans-don-hangs/search-items`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransDonHangControllerSearchItemsQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>, TError = ErrorType<unknown>>(params?: QldhTransDonHangControllerSearchItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerSearchItemsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>> = ({ signal }) => qldhTransDonHangControllerSearchItems(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerSearchItemsQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>>
export type QldhTransDonHangControllerSearchItemsQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerSearchItems = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>, TError = ErrorType<unknown>>(
 params?: QldhTransDonHangControllerSearchItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchItems>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerSearchItemsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerSearchThongKe = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransDonHangControllerSearchThongKe200Item[]>(
      {url: `/qldh-trans-don-hangs/search-thong-ke`, method: 'GET', signal
    },
      );
    }
  

export const getQldhTransDonHangControllerSearchThongKeQueryKey = () => {
    return [`/qldh-trans-don-hangs/search-thong-ke`] as const;
    }

    
export const getQldhTransDonHangControllerSearchThongKeQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerSearchThongKeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>> = ({ signal }) => qldhTransDonHangControllerSearchThongKe(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerSearchThongKeQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>>
export type QldhTransDonHangControllerSearchThongKeQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerSearchThongKe = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerSearchThongKe>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerSearchThongKeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerUpdateTrangThaiDonHang = (
    id: number,
    qldhTransDonHangControllerUpdateTrangThaiDonHangBody: QldhTransDonHangControllerUpdateTrangThaiDonHangBody,
 ) => {
      
      
      return drcInstance<QldhTransDonHang>(
      {url: `/qldh-trans-don-hangs/${id}/trang-thai`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: qldhTransDonHangControllerUpdateTrangThaiDonHangBody
    },
      );
    }
  


export const getQldhTransDonHangControllerUpdateTrangThaiDonHangMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>, TError,{id: number;data: QldhTransDonHangControllerUpdateTrangThaiDonHangBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>, TError,{id: number;data: QldhTransDonHangControllerUpdateTrangThaiDonHangBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>, {id: number;data: QldhTransDonHangControllerUpdateTrangThaiDonHangBody}> = (props) => {
          const {id,data} = props ?? {};

          return  qldhTransDonHangControllerUpdateTrangThaiDonHang(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerUpdateTrangThaiDonHangMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>>
    export type QldhTransDonHangControllerUpdateTrangThaiDonHangMutationBody = QldhTransDonHangControllerUpdateTrangThaiDonHangBody
    export type QldhTransDonHangControllerUpdateTrangThaiDonHangMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerUpdateTrangThaiDonHang = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>, TError,{id: number;data: QldhTransDonHangControllerUpdateTrangThaiDonHangBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateTrangThaiDonHang>>,
        TError,
        {id: number;data: QldhTransDonHangControllerUpdateTrangThaiDonHangBody},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerUpdateTrangThaiDonHangMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerUpdateById = (
    id: number,
    qldhTransDonHangExcludingDonHangIdTrangThaiWithRelations: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-don-hangs/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: qldhTransDonHangExcludingDonHangIdTrangThaiWithRelations
    },
      );
    }
  


export const getQldhTransDonHangControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>, TError,{id: number;data: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>, TError,{id: number;data: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>, {id: number;data: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations}> = (props) => {
          const {id,data} = props ?? {};

          return  qldhTransDonHangControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>>
    export type QldhTransDonHangControllerUpdateByIdMutationBody = QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations
    export type QldhTransDonHangControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>, TError,{id: number;data: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerUpdateById>>,
        TError,
        {id: number;data: QldhTransDonHangExcludingDonHangIdTrangThaiWithRelations},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerFindById = (
    id: number,
    params?: QldhTransDonHangControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransDonHangWithRelations>(
      {url: `/qldh-trans-don-hangs/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransDonHangControllerFindByIdQueryKey = (id: number,
    params?: QldhTransDonHangControllerFindByIdParams,) => {
    return [`/qldh-trans-don-hangs/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransDonHangControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: QldhTransDonHangControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>> = ({ signal }) => qldhTransDonHangControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>>
export type QldhTransDonHangControllerFindByIdQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerFindById = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: QldhTransDonHangControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransDonHangControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-don-hangs/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getQldhTransDonHangControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  qldhTransDonHangControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>>
    
    export type QldhTransDonHangControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerCreate = (
    newQldhTransDonHang: NewQldhTransDonHang,
 ) => {
      
      
      return drcInstance<QldhTransDonHangWithRelations>(
      {url: `/qldh-trans-don-hangs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newQldhTransDonHang
    },
      );
    }
  


export const getQldhTransDonHangControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>, TError,{data: NewQldhTransDonHang}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>, TError,{data: NewQldhTransDonHang}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>, {data: NewQldhTransDonHang}> = (props) => {
          const {data} = props ?? {};

          return  qldhTransDonHangControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransDonHangControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>>
    export type QldhTransDonHangControllerCreateMutationBody = NewQldhTransDonHang
    export type QldhTransDonHangControllerCreateMutationError = ErrorType<unknown>

    export const useQldhTransDonHangControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>, TError,{data: NewQldhTransDonHang}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransDonHangControllerCreate>>,
        TError,
        {data: NewQldhTransDonHang},
        TContext
      > => {

      const mutationOptions = getQldhTransDonHangControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransDonHangControllerFind = (
    params?: QldhTransDonHangControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransDonHangWithRelations[]>(
      {url: `/qldh-trans-don-hangs`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransDonHangControllerFindQueryKey = (params?: QldhTransDonHangControllerFindParams,) => {
    return [`/qldh-trans-don-hangs`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransDonHangControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>, TError = ErrorType<unknown>>(params?: QldhTransDonHangControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransDonHangControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>> = ({ signal }) => qldhTransDonHangControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransDonHangControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>>
export type QldhTransDonHangControllerFindQueryError = ErrorType<unknown>

export const useQldhTransDonHangControllerFind = <TData = Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>, TError = ErrorType<unknown>>(
 params?: QldhTransDonHangControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransDonHangControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransDonHangControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



