import { MuiDataGrid } from 'main/components/atoms/mui-data-grid';
import EditPermissionAction from 'main/components/molecules/permission-action/EditPermissionAction';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  initialData,
  insertNewSupplies,
  selectSuppliesDetails,
  updateSuppliesDetail,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-add-slice';
import { generateUUID } from 'main/utils/generateUUID';
import { useCallback } from 'react';
import { Button } from 'reactstrap';
import useTableConfig from './useTableConfig';

function SuppliesStoreImportAddTable() {
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectSuppliesDetails);

  const { suppliesIsLoading, columns } = useTableConfig();

  const handleRowEditStop = useCallback(
    (params: any, event: any) => {
      const { rowId, newValue } = params;

      // Dispatch an action to update the row data in Redux
      dispatch(updateSuppliesDetail({ id: rowId, changes: newValue }));

      // Add new row logic (if last row is edited)
      if (params.reason === 'enterKeyDown' && params.row.id === suppliesDetails.length - 1) {
        const newRows = Array.from({ length: 10 }, () => ({
          ...initialData,
          id: generateUUID(),
        }));
        dispatch(insertNewSupplies(newRows));
      }
    },
    [dispatch, suppliesDetails.length],
  );

  const handleAddRow = useCallback(() => {
    const newRows = Array.from({ length: 10 }, () => ({
      ...initialData,
      id: generateUUID(),
    }));

    dispatch(insertNewSupplies(newRows));
  }, [dispatch]);

  const handleRowEdit = useCallback(
    (newRow: any) => {
      const updatedRow = {
        ...newRow,
        soLuong: newRow.soLuong,
      };

      dispatch(
        updateSuppliesDetail({
          id: newRow.id,
          changes: {
            ...updatedRow,
          },
        }),
      );

      return updatedRow;
    },
    [dispatch],
  );

  return (
    <>
      <div className="d-flex justify-content-end pb-4">
        <EditPermissionAction>
          <Button color="primary" onClick={handleAddRow}>
            Thêm hàng
          </Button>
        </EditPermissionAction>
      </div>
      {/* <div style={{ height: 650, width: '100%' }}> */}
      <MuiDataGrid
        rows={suppliesDetails}
        columns={columns}
        loading={suppliesIsLoading}
        pageSizeOptions={[10, 25, 50, 100]}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={handleRowEdit}
      />
    </>
  );
}

export default SuppliesStoreImportAddTable;
