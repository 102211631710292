import ExcelJS from 'exceljs';
import { KhovtXuatNhapTonControllerFind200DataItem } from 'main/apis/drc/models';
import { InventoryFilter } from 'main/redux/modules/supplies-store-inventory/supplies-store-inventory-list-slice';
import { AbstractExcelWriter } from 'main/utils/excel-writer';
import { writeDataSheetTHNXT } from './services';

class SuppliesStoreInventoryExcelWriter extends AbstractExcelWriter<{
  filter: InventoryFilter;
  content: {
    inventoryItems: KhovtXuatNhapTonControllerFind200DataItem[];
  };
}> {
  protected async writeDataImpl(): Promise<ExcelJS.Buffer> {
    const workbook = this.workbook;
    if (!workbook) {
      throw new Error('Invalid template');
    }

    // 1. Sheet[04 TH NXT]
    this.writeDataSheetTHNXT(workbook);

    return await workbook.xlsx.writeBuffer();
  }

  private writeDataSheetTHNXT(workbook: ExcelJS.Workbook): void {
    writeDataSheetTHNXT(workbook, { filter: this.data?.filter, items: this.data?.content.inventoryItems });
  }
}

export default SuppliesStoreInventoryExcelWriter;
