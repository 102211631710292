const DateFormat = {
  DATE_TIME_LONG: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_LONG_VN_NOSPACE: 'DDMMYYYYHHmmss',
  DATE_VN_NOSPACE: 'DDMMYYYY',
  DATE_TIME_SHORT: 'YYYY-MM-DD HH:mm',
  DATE_ONLY: 'YYYY-MM-DD',
  DATE_ONLY_VN: 'DD/MM/YYYY',
  DATE_TIME_VN: 'DD/MM/YYYY HH:mm:ss',
  MM_YYYY_VN: 'MM/YYYY',
  LONG_DATE_ONLY_VN: '[Ngày] DD [tháng] MM [năm] YYYY',
  TIME_ONLY: 'HH:mm:ss',
  TIME_ONLY_SHORT: 'HH:mm',
  ISO_8601: "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'",
  ISO_8601_DATE_ONLY: 'YYYY-MM-DDT00:00:00.000[Z]',
};

export { DateFormat };
