import { CircularProgress, FormGroup } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useKhovtTransPhieuNhapControllerFindDetail } from 'main/apis/drc/endpoints/khovt-trans-phieu-nhap-controller/khovt-trans-phieu-nhap-controller';
import AutocompleteCell from 'main/components/atoms/autocomplete/AutocompleteCell';
import { getMaVtFromOptionLabel } from 'main/components/pages/admin/supplies-store-import/supplies-store-import-add/sub-components/supplies-store-import-add-table/useTableConfig';
import { Color } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { SuppliesFilterOptions } from 'main/hooks/useSuppliesExportOptions';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  deleteSuppliesDetail,
  selectSuppliesDetails,
  updateSuppliesDetail,
} from 'main/redux/modules/supplies-store-export/supplies-store-export-add-slice';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

const useTableConfig = (khoXuat: string) => {
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectSuppliesDetails);

  const {
    data: suppliesOptions,
    isLoading: suppliesIsLoading,
    refetch,
  } = useKhovtTransPhieuNhapControllerFindDetail(
    {
      filter: {
        where: {
          // khoXuat: khoXuat !== '' ? khoXuat : undefined,
          maKho: khoXuat !== '' ? khoXuat : undefined,
          // donViNhap: khoNhap !== '' ? khoNhap : undefined,
        },
      },
    },
    {
      query: {
        queryKey: ['suppliesOptions', khoXuat],
        enabled: !!khoXuat,
      },
    },
  );

  useEffect(() => {
    if (khoXuat) {
      refetch();
    }
  }, [khoXuat, refetch]);

  const filteredSuppliesOptions = useMemo(() => {
    const options: SuppliesFilterOptions = [];

    if (suppliesOptions && suppliesOptions.data) {
      const dataSupplier = suppliesOptions.data
        .map((item) => ({
          ...item,
          id: item.id,
          text: item.tenVt,
          idNhap: item.id,
        }))
        ?.filter((itemFilter) => Number(itemFilter?.tonKho) > 0) as SuppliesFilterOptions;

      options.push(...dataSupplier);
    }

    return options;
  }, [suppliesOptions]);

  const [deletingRows, setDeletingRows] = useState<number[]>([]);

  const handleDeleteSuppliesDetails = async (id: number) => {
    if (deletingRows.includes(id)) {
      return;
    }

    setDeletingRows((prev) => [...prev, id]);
    try {
      await dispatch(deleteSuppliesDetail(id));
    } catch (error) {
    } finally {
      setDeletingRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };

  const columns: GridColDef[] = [
    {
      field: FieldName.STT,
      headerName: 'STT',
      editable: false,
      sortable: false,
      flex: 0.5,
      cellClassName: 'disable-gray',
      renderCell: (params: any) => {
        const index = suppliesDetails.findIndex((row) => row.id === params.id);
        return index !== -1 ? index + 1 : '';
      },
      //
    },
    {
      field: FieldName.MA_VT,
      headerName: 'Mã vật tư',
      flex: 3,
      renderCell: (params) => {
        return (
          filteredSuppliesOptions?.length > 0 && (
            <AutocompleteCell
              value={params.value}
              options={filteredSuppliesOptions}
              getOptionLabel={(option) =>
                option &&
                option.maVt +
                  ' - ' +
                  option.tenVt +
                  ' - ' +
                  option.soLo +
                  ' - ' +
                  option.viTriLuuKho +
                  ' - ' +
                  option.tonKho
              }
              customDisplayHandler={{
                getDisplayFromOption: (option) => option.maVt,
                getDisplayFromOptionLabel: getMaVtFromOptionLabel,
              }}
              onChange={(newValue) => {
                if (newValue) {
                  const { idNhap, maVt, soLo, tenVt, nguonGoc, tonKho, dvt } = newValue;
                  dispatch(
                    updateSuppliesDetail({
                      id: params.row?.id,
                      changes: {
                        idNhap: idNhap,
                        maVt: maVt,
                        maLo: soLo,
                        tenVt: tenVt,
                        nguonGoc: nguonGoc,
                        tonKho: tonKho,
                        dvt: dvt,
                        // soLuongThucTe: soLuongThucTe,
                        // soLuongYeuCau: soLuongYeuCau,
                        // tonKhoDuKien:
                        //   Number(soLuongThucTe) > Number(tonKho)
                        //     ? Number(tonKho)
                        //     : Number(tonKho) - Number(soLuongThucTe),
                      },
                    }),
                  );
                }
              }}
            />
          )
        );
      },
    },
    {
      field: FieldName.TEN_VT,
      headerName: 'Tên vật tư',
      flex: 3,
      editable: true,
      type: 'string',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="text"
            value={params.value || ''}
            onChange={(e) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.DVT,
      headerName: 'Đơn vị tính',
      cellClassName: 'disable-gray',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: FieldName.SO_LUONG_YEU_CAU,
      headerName: 'Số lượng yêu cầu',
      type: 'number',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            value={params.value || ''}
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.SO_LUONG_THUC_TE,
      headerName: 'Số lượng thực xuất',
      flex: 1,
      type: 'number',
      editable: true,
      sortable: false,
      cellClassName: (params) => {
        const { tonKho, soLuongThucTe } = params.row;
        return Number(soLuongThucTe) > Number(tonKho) ? 'error-cell' : Color.LIGHT_GRAY;
      },
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            value={params.value || ''}
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.TON_KHO,
      headerName: 'Tồn kho hiện tại',
      flex: 1,
      editable: false,
      sortable: false,
      cellClassName: 'disable-gray',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            disabled
            className="disable-gray"
            value={params.value || ''}
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      editable: false,
      cellClassName: 'disable-gray',
      field: FieldName.TON_KHO_DU_KIEN,
      headerName: 'Tồn kho dự kiến',
      flex: 1,
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            value={params.value || ''}
            disabled
            className="disable-gray"
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.MA_LO,
      headerName: 'Mã số lô',
      cellClassName: 'disable-gray',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: FieldName.NGUON_GOC,
      headerName: 'Nguồn gốc',
      cellClassName: 'disable-gray',
      flex: 1.5,
      editable: false,
      sortable: false,
    },
    {
      sortable: false,
      field: 'actions',
      headerName: 'Thao Tác',
      flex: 0.5,
      align: 'center',
      renderCell: (params) => {
        const isDeleting = deletingRows.includes(params?.row?.id);
        return (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              if (!isDeleting) {
                handleDeleteSuppliesDetails(params?.row?.id);
              }
            }}
            style={{ pointerEvents: isDeleting ? 'none' : 'auto', opacity: isDeleting ? 0.5 : 1 }}>
            {isDeleting ? <CircularProgress size={20} /> : <i className="fa-solid fa-trash" title="Xóa" />}
          </Link>
        );
      },
    },
  ];

  return { suppliesIsLoading, columns };
};

export default useTableConfig;
