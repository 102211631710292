import { Autocomplete, TextField } from '@mui/material';
import { SuppliesFilter, SuppliesFilterOptions } from 'main/hooks/useSuppliesOptions';
import React, { useCallback, useMemo, useState } from 'react';

export type onChangeType = SuppliesFilter & {
  soLuong?: number;
  tonKho?: number;
  soLo?: number;
  maLo?: string;
  nguonGoc?: number;
  idNhap?: number;
  thoiHanLuuKho?: number | null;
  soLuongThucTe?: number;
  soLuongYeuCau?: number;
  ngayHetHanLK?: string;
};

interface AutocompleteCellProps {
  options: SuppliesFilterOptions;
  value: SuppliesFilter | null;
  onChange: (newValue: onChangeType | null) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  getOptionLabel?: (option: any) => string;
  placeholder?: string;
  customDisplayHandler?: {
    getDisplayFromOption?: (option: any) => string;
    getDisplayFromOptionLabel?: (optionLabel: string) => string;
  };
}

const AutocompleteCell: React.FC<AutocompleteCellProps> = ({
  options,
  value,
  onChange,
  inputRef,
  getOptionLabel,
  placeholder,
  customDisplayHandler,
}) => {
  const { inputValue, inputValueParams, handleInputValueWhenOptionChange } = useCustomLabelDisplay(
    customDisplayHandler?.getDisplayFromOption,
    customDisplayHandler?.getDisplayFromOptionLabel,
  );

  return (
    <Autocomplete
      getOptionKey={(e: any) => e.id}
      options={options}
      // value={value}
      getOptionLabel={getOptionLabel}
      onChange={(_event, newValue) => {
        if (customDisplayHandler) {
          handleInputValueWhenOptionChange(newValue);
        }
        onChange(newValue); // callback handle change
      }}
      {...inputValueParams}
      onKeyDown={(e) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === ' ') {
          e.stopPropagation();
        }
      }}
      style={{ whiteSpace: 'normal', overflow: 'visible', lineHeight: 2.5 }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          variant="outlined"
          size="medium"
          placeholder={placeholder}
          value={customDisplayHandler ? inputValue : placeholder}
        />
      )}
    />
  );
};

function useCustomLabelDisplay(
  getDisplayFromOption?: (option: any) => string,
  getDisplayFromOptionLabel?: (optionLabel: string) => string,
) {
  // React State
  const [inputValue, setInputValue] = useState('');
  const inputValueParams = useMemo(
    () =>
      getDisplayFromOption
        ? {
            inputValue,
            onInputChange: (_: any, newInputValue: any) =>
              setInputValue(getDisplayFromOptionLabel ? getDisplayFromOptionLabel(newInputValue) : newInputValue),
          }
        : {},
    [getDisplayFromOption, getDisplayFromOptionLabel, inputValue],
  );

  // React Function
  const handleInputValueWhenOptionChange = useCallback(
    (newOptionValue: any) => {
      if (newOptionValue) {
        setInputValue(getDisplayFromOption ? getDisplayFromOption(newOptionValue) : newOptionValue.id);
      } else {
        setInputValue('');
      }
    },
    [getDisplayFromOption],
  );

  return {
    inputValue,
    inputValueParams,
    handleInputValueWhenOptionChange,
  };
}

export default AutocompleteCell;
